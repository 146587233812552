import { Row, Col, Button } from 'antd';
// import { Link } from 'react-router-dom';
// import { ReactComponent as Logo } from '@/src/assets/logo.svg';
import PackageLogo from './PackageLogo';
import Icon from '@/src/components/Icon';
import SelectedItemPilles from '@components/SelectedItemPilles';

export function PackageCard({ record, span = 6, showAssetLink, disabled, deleteItem, edit }) {
  return (
    <Col span={span} className="mb-4">
      <div className="w-full bg-seperator rounded-lg shadow-md flex flex-col px-4 py-4 relative h-full justify-between">
        <Row className="w-full flex justify-between" gutter={0}>
          <Col span={4}>
            <PackageLogo
              disabled={disabled}
              package={record}
              className=" w-12 h-12 object-contain"
              //   onChange={(logo) => {
              //     update({
              //       ...record,
              //       iconFile: [logo]
              //     });
              //   }}
            />
          </Col>

          <Col span={14} className="" title={record.displayName}>
            <h2 className="mt-2 text-ellipsis w-full">{record.displayName}</h2>
          </Col>

          <Col span={5} className="flex">
            <Button type="text" onClick={() => edit(record)}>
              <Icon name="edit" style={{ fontSize: '1.1rem' }} />
            </Button>

            <Button type="text" onClick={() => deleteItem(record)}>
              <Icon name="delete" style={{ fontSize: '1.1rem' }} />
            </Button>
          </Col>
        </Row>

        <Row gutter={0} className="my-2 flex items-center">
          <Col span={24} className="text-ellipsis" title={record.description}>
            {record.description}
          </Col>
        </Row>
        {/* <Col span={10} className="flex justify-end"> */}
        <div className="inline-block">
          <SelectedItemPilles value={record.tags || []} className="inline-block" />
        </div>
        {/* </Col> */}

        <div className="flex w-full justify-between mt-2">
          <div className="flex ">
            <span className="p-2 mr-2  " style={{ border: '1px solid #cab6ba' }}>
              {record.version}
            </span>
            <span className="p-2  " style={{ border: '1px solid #cab6ba' }}>
              {record.pkgType}
            </span>
          </div>
          <span className="flex flex-col justify-end">
            <Icon name={`platform_${record.os}`} style={{ fontSize: '1.5rem' }} />
          </span>
        </div>
      </div>
    </Col>
  );
}
