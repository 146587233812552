import { useState, useEffect } from 'react';

import { Button, Tag, Segmented, Row, Col, Checkbox } from 'antd';
import { CrudProvider } from '@/src/hooks/crud';
import {
  bulkDeletePackageApi,
  createPackageApi,
  deletePackageApi,
  getAllPackagesApi,
  updatePackageApi,
  getPackageTagsApi
} from '../packages';
import PermissionChecker from '@/src/components/PermissionChecker';
import constants from '@/src/constants/index';
import PackageForm from '../components/PackageForm';
import { User } from '@/src/components/pickers/UserPicker';
import Icon from '@/src/components/Icon';
import PackageLogo from '../components/PackageLogo';
import BulkDeleteButton from '@/src/components/BulkDeleteButton';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { PackageCard } from '@/src/modules/device-automation/components/PackageCard';
import SelectedItemPilles from '@components/SelectedItemPilles';
import { Spin } from 'antd';
import PageHeading from '@/src/components/PageHeading';

export default function Packages({ fetchFn, disabled }) {
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isTagLoading, setTagLoading] = useState(true);

  function updateSelectedTags(ckecked, tag, forSelectAll) {
    if (forSelectAll) {
      if (ckecked) {
        setSelectedTags(tagOptions.map((t) => t.tag));
      } else {
        setSelectedTags([]);
      }
    } else {
      if (ckecked) {
        setSelectedTags([...selectedTags, tag]);
      } else {
        setSelectedTags(selectedTags.filter((t) => t !== tag));
      }
    }
  }

  function getTags() {
    getPackageTagsApi().then((tags) => {
      setTagLoading(false);

      setTagOptions(tags);
    });
  }

  useEffect(() => {
    getTags();

    // eslint-disable-next-line
  }, []);
  const [viewType, setViewType] = useState('card');

  const columns = [
    {
      title: 'ID',
      dataIndex: 'name',
      key: 'name',
      type: 'view_link'
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render({ record, update }) {
        return (
          <div className="flex items-center">
            <PackageLogo
              disabled={disabled}
              package={record}
              style={{ width: '30px' }}
              className="mr-2"
              onChange={(logo) => {
                update({
                  ...record,
                  iconFile: [logo]
                });
              }}
            />
            <div>{record.displayName}</div>
          </div>
        );
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      align: 'center',
      sortable: false,
      render({ record }) {
        return (
          <div className="text-xl">
            <Icon name={`platform_${record.os}`} />
          </div>
        );
      }
    },
    {
      title: 'Version',
      dataIndex: 'version',
      align: 'center',
      key: 'version',
      sortable: false
    },
    {
      title: 'Type',
      dataIndex: 'pkgType',
      align: 'center',
      key: 'pkgType',
      sortable: false,
      render({ record }) {
        return (
          <Tag
            color={'processing'}
            className="inline-flex items-center justify-center"
            style={{
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
            {record.pkgType}
          </Tag>
        );
      }
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      sortable: false,
      render({ record }) {
        return (
          <SelectedItemPilles value={record.tags || []} className="inline-block" maxItems={1} />
        );
      }
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sortable: false,
      render({ record }) {
        return <User.Picker textOnly value={record.createdBy} disabled />;
      }
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      type: 'datetime'
    },
    ...(disabled
      ? []
      : [
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            editPermissions: [constants.Update_Software],
            deletePermissions: [constants.Delete_Software]
          }
        ])
  ];

  return (
    <Row className="h-full ">
      <Col span={18} className="h-full">
        <PageHeading icon="app" title="Software Automation" />
        <User.Provider>
          <CrudProvider
            key={selectedTags.join(',')}
            className="h-full"
            columns={columns}
            defaultFormItem={{
              selfServiceSupported: true,
              os: 'linux',
              osArch: 'x64',
              pkgType: 'zip',
              pkgLocation: 'local_dir'
            }}
            resourceTitle="Application"
            disableFormScrolling
            allowSelection={!disabled}
            hasSearch
            fetchFn={(...args) => {
              if (fetchFn) {
                return fetchFn(...args);
              } else {
                return getAllPackagesApi(...args, { selectedTags }).then((res) => {
                  return res;
                });
              }
            }}
            deleteFn={deletePackageApi}
            createFn={(...args) => {
              return createPackageApi(...args).then((res) => {
                getTags();
                return res;
              });
            }}
            updateFn={(...args) => {
              return updatePackageApi(...args).then((res) => {
                getTags();
                return res;
              });
            }}
            disableColumnSelection={disabled || viewType === 'card'}
            createAfterSlot={(_, { selectedItems, fetchData, setSelectedItems }) => (
              <BulkDeleteButton
                selectedItems={selectedItems}
                resourceTitle="Applications"
                className="ml-2"
                onConfirm={() =>
                  bulkDeletePackageApi(selectedItems).then(() => {
                    setSelectedItems([]);
                    return fetchData();
                  })
                }
              />
            )}
            createSlot={(createFn) =>
              !disabled && (
                <>
                  <PermissionChecker permission={constants.Create_Software}>
                    <Button type="primary" className="mr-2" onClick={createFn}>
                      Create
                    </Button>
                  </PermissionChecker>
                  <Segmented
                    value={viewType}
                    onChange={(e) => setViewType(e)}
                    options={[
                      { value: 'list', icon: <BarsOutlined /> },
                      { value: 'card', icon: <AppstoreOutlined /> }
                    ]}
                  />
                </>
              )
            }
            formFields={(item) => <PackageForm item={item} />}
            tableSlot={
              viewType === 'card'
                ? ({ data, edit, deleteItem }) => (
                    <div className="px-2">
                      <Row gutter={16}>
                        {data.map((item) => (
                          <PackageCard
                            key={item.id}
                            record={item}
                            span={8}
                            disabled={disabled}
                            deleteItem={deleteItem}
                            edit={edit}
                          />
                        ))}
                      </Row>
                    </div>
                  )
                : null
            }
          />
        </User.Provider>
      </Col>

      <Col span={6} className="h-full 	pt-12	pb-3">
        <div className="w-full flex flex-col  bg-seperator rounded-lg p-4  overflow-scroll h-full ">
          <h1 className="">Categories</h1>

          {isTagLoading ? (
            <div className="w-full flex flex-col   flex-1  item-center justify-center">
              <Spin spinning />
            </div>
          ) : (
            <>
              {tagOptions?.length ? (
                <Row gutter={0} className="w-full mb-2">
                  <Col span={12} className="">
                    <Row gutter={0} className="w-full items-center">
                      <Col span={2} className="">
                        <Checkbox
                          className="no-bg-ckeckbox"
                          checked={selectedTags?.length === tagOptions?.length}
                          onChange={(e) =>
                            updateSelectedTags(e.target.checked, undefined, true)
                          }></Checkbox>
                      </Col>
                      <Col span={22} className=" h-full text-ellipsis">
                        <span className=" ml-3"> Select All</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}

              {tagOptions.map((item, index) => (
                <Row gutter={0} key={index} className="w-full mb-2">
                  <Col span={12} className="">
                    <Row gutter={0} key={index} className="w-full items-center">
                      <Col span={2} className="">
                        <Checkbox
                          className="no-bg-ckeckbox"
                          checked={selectedTags.includes(item.tag)}
                          onChange={(e) =>
                            updateSelectedTags(e.target.checked, item.tag)
                          }></Checkbox>
                      </Col>
                      <Col span={22} className=" h-full text-ellipsis" title={item.tag}>
                        <span className=" ml-3"> {item.tag}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12} className="flex items-center justify-end">
                    <span className="ml-2">{item.tagCount}</span>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </div>
      </Col>
    </Row>
  );
}
