import { Menu, Input } from 'antd';
import Icon from '@components/Icon';
import { SettingOutlined, AuditOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Permissions } from '@/src/components/Permissions';
import constants from '@/src/constants/index';
import { useSettingsLayout } from '../layout/SettingsLayout';
import Flatten from 'lodash/flatten';

const menuItems = [
  {
    label: 'User Management',
    key: 'user-management',
    icon: <Icon name="user" style={{ fontSize: '1.2rem' }} />,
    permissions: [constants.View_Settings],
    children: [
      {
        label: 'Organization',
        key: 'organizations',
        permissions: [constants.View_Settings]
      },
      {
        label: 'Department',
        key: 'departments',
        permissions: [constants.View_Settings]
      },
      // {
      //   label: 'Teams',
      //   key: 'teams'
      // },
      {
        label: 'User Roles',
        key: 'user-roles',
        permissions: [constants.View_Settings]
      },
      {
        label: 'Users',
        key: 'users',
        permissions: [constants.View_Settings]
      }
    ]
  },
  {
    label: 'System Settings',
    key: 'system-settings',
    icon: <SettingOutlined style={{ fontSize: '1.2rem' }} />,
    children: [
      {
        label: 'Branding',
        key: 'branding',
        permissions: [constants.View_Settings]
      },
      {
        label: 'Vendor Logo',
        key: 'logo-management',
        permissions: [constants.View_Settings]
      },
      {
        label: 'Mail Server Configurations',
        key: 'mail-server-config',
        permissions: [constants.View_Settings]
      },
      {
        label: 'LDAP Server Configurations',
        key: 'ldap-server-config',
        permissions: [constants.View_Settings]
      },
      {
        label: 'ZiroScore Settings',
        key: 'ziroscore-settings',
        permissions: [constants.View_Settings]
      },
      {
        label: 'Threat Database',
        key: 'threat-database',
        permissions: [constants.Manage_Settings]
      },
      {
        label: 'Blacklisted Ports',
        key: 'blacklisted-ports',
        permissions: [constants.Manage_Settings]
      },
      {
        label: 'License Management',
        key: 'license-management',
        permissions: [constants.Manage_Settings]
      },
      {
        label: 'Remote Desktop Settings',
        key: 'remote-desktop-settings',
        permissions: [constants.Manage_Settings]
      },
      {
        label: 'Server Settings',
        key: 'server-settings',
        permissions: [constants.Manage_Settings]
      },
      {
        label: 'License Edition',
        key: 'license-edition',
        permissions: [constants.Manage_Settings]
      },
      {
        label: 'Currency',
        key: 'currency',
        permissions: [constants.Manage_Settings]
      }
    ]
  },
  {
    label: 'Market Place',
    key: 'integrations',
    permissions: [constants.View_Settings],
    icon: <Icon name="policy" style={{ fontSize: '1.2rem' }} />
  },
  {
    label: 'Inventory Profile',
    key: 'inventory-profiles',
    icon: <Icon name="inventory" style={{ fontSize: '1.2rem' }} />,
    permissions: [constants.View_Settings],
    children: [
      {
        label: 'Host',
        key: 'hosts'
      }
      // {
      //   label: 'Cloud',
      //   key: 'cloud'
      // },
      // {
      //   label: 'Container',
      //   key: 'container'
      // }
    ]
  },
  // {
  //   label: 'IAM',
  //   key: 'iam',
  //   icon: <Icon name="lock" style={{ fontSize: '1.2rem' }} />,
  //   children: [
  //     {
  //       label: 'AD Settings',
  //       key: 'ad'
  //     },
  //     {
  //       label: 'SAML 2.0',
  //       key: 'saml'
  //     },
  //     {
  //       label: 'RADIUS',
  //       key: 'radius'
  //     },
  //     {
  //       label: 'Password Policy',
  //       key: 'password-policy'
  //     }
  //   ]
  // },
  // {
  //   label: 'Tag Management',
  //   key: 'tag-management',
  //   icon: <Icon name="tag" style={{ fontSize: '1.2rem' }} />,
  //   children: [
  //     {
  //       label: 'Security',
  //       key: 'security'
  //     },
  //     {
  //       label: 'Risk',
  //       key: 'risk'
  //     },
  //     {
  //       label: 'Device',
  //       key: 'device'
  //     }
  //   ]
  // },
  {
    label: 'Agent Management',
    key: 'agent-management',
    icon: <Icon name="agent" style={{ fontSize: '1.2rem' }} />,
    permissions: [constants.View_Settings],
    children: [
      {
        label: 'Agent Configuration',
        key: 'agent-configuration'
      },
      {
        label: 'Enroll Secret',
        key: 'enroll-secret'
      }
      // {
      //   label: 'Package Config',
      //   key: 'package-config'
      // },
      // {
      //   label: 'Deployment',
      //   key: 'deployment'
      // }
    ]
  },
  {
    label: 'Deployment Policies',
    key: 'software-deployment',
    icon: <Icon name="automation" style={{ fontSize: '1.2rem' }} />,
    permissions: [constants.View_Settings]
  },
  {
    label: 'Patch Management',
    key: 'patch-management',
    icon: <Icon name="patch" style={{ fontSize: '1.2rem' }} />,
    permissions: [constants.View_Settings],
    children: [
      // {
      //   label: 'Patch Approval Policy',
      //   key: 'patch-approval-policy',
      //   permissions: [constants.View_Settings]
      // },
      {
        label: 'Computer Groups',
        key: 'computer-groups',
        permissions: [constants.View_Settings]
      },
      {
        label: 'Patch Prefrences',
        key: 'patch-preference',
        permissions: [constants.View_Settings]
      }

      // {
      //   label: 'Patch Decline Policy',
      //   key: 'patch-decline-policy',
      //   permissions: [constants.View_Settings]
      // },
      // {
      //   label: 'System Health',
      //   key: 'system-health',
      //   permissions: [constants.View_Settings]
      // },
      // {
      //   label: 'Update Patch Database',
      //   key: 'update-patch-database',
      //   permissions: [constants.View_Settings]
      // }
    ]
  },
  {
    label: 'Policy Management',
    key: 'policy-management',
    permissions: [constants.View_Settings, constants.View_Quick_Checks],
    icon: <Icon name="policy" style={{ fontSize: '1.2rem' }} />,
    children: [
      {
        label: 'Alert Configurations',
        key: 'policies',
        permissions: [constants.View_Alert]
      },
      {
        label: 'Rootkit Configurations',
        key: 'rootkit',
        permissions: [constants.View_Settings]
      },
      // {
      //   label: 'Event Forwarding',
      //   key: 'event-forwarding',
      //   permissions: [constants.View_Settings]
      // },
      {
        label: 'End Point Vital',
        key: 'quick-check-toolbar',
        permissions: [constants.View_Quick_Checks]
      }
    ]
  },
  // {
  //   label: 'Database Settings',
  //   key: 'database-settings',
  //   permissions: [constants.View_Settings],
  //   icon: <Icon name="database" style={{ fontSize: '1.2rem' }} />
  // },
  {
    label: 'Audit',
    key: 'audit',
    permissions: [constants.View_Audit],
    icon: <AuditOutlined className="mr-2 text-lg" name="alert" style={{ fontSize: '1.2rem' }} />
  }
];

export default function SettingsMenu() {
  const layout = useSettingsLayout();

  const { hasPermission, permissions } = Permissions.usePermission();
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [filteredMenu, setFilteredMenu] = useState(
    menuItems
      .map(function filterRecurssive(item) {
        if (!item.permissions || hasPermission(item.permissions, true)) {
          let children = (
            (item.children || []).length ? item.children.filter(filterRecurssive) : []
          ).filter(Boolean);
          return {
            ...item,
            ...(children.length ? { children } : {})
          };
        }
        return null;
      })
      .filter(Boolean)
  );
  const [openKeys, setOpenKeys] = useState(['user-management']);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const route = useLocation();

  const navigate = useNavigate();

  function setselectedmenu(key) {
    let menukey = key;

    if (!key) {
      const path = route.pathname.replace('/settings/', '');
      const pathParts = path.split('/');

      menukey = pathParts[1];
    }

    const selectedMenuitem = Flatten(menuItems.map((i) => [...(i.children || []), { ...i }])).find(
      (i) => i?.key === menukey
    );

    layout.setactiveMenu(selectedMenuitem);
  }

  useEffect(() => {
    setselectedmenu();
    // eslint-disable-next-line
  }, [selectedKeys]);

  useEffect(() => {
    const path = route.pathname.replace('/settings/', '');
    const pathParts = path.split('/');
    setOpenKeys([pathParts[0]]);

    if (pathParts.length > 1) {
      setSelectedKeys([pathParts[1]]);
    } else {
      setSelectedKeys([pathParts[0]]);
    }
  }, [route]);

  useEffect(() => {
    const menu = filteredMenu
      .map(function filterRecurssive(item) {
        let children = (
          (item.children || []).length ? item.children.filter(filterRecurssive) : []
        ).filter(Boolean);
        if (!item.permissions || hasPermission(item.permissions, true)) {
          return {
            ...item,
            ...(children.length ? { children } : {})
          };
        }
        return null;
      })
      .filter(Boolean);
    setFilteredMenu(menu);
    // eslint-disable-next-line
  }, [permissions, hasPermission]);

  function handleOnMenuSelected({ keyPath, key }) {
    const path = keyPath.reverse();
    navigate(`/settings/${path.join('/')}`);
  }

  function onSearch(value) {
    if (value && value.length) {
      setSearchTerm(value);
      let menu = menuItems.filter(function filterRecurssive(item) {
        return (
          item.label.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
          ((item.children || []).length ? item.children.some(filterRecurssive) : false)
        );
      });
      menu = menu
        .map(function filterRecurssive(item) {
          if (!item.permissions || hasPermission(item.permissions, true)) {
            let children = (
              (item.children || []).length ? item.children.filter(filterRecurssive) : []
            ).filter(Boolean);
            return {
              ...item,
              ...(children.length ? { children } : {})
            };
          }
          return null;
        })
        .filter(Boolean);
      setFilteredMenu(menu);
      setOpenKeys(menu.map((i) => i.key));
    } else {
      setOpenKeys(['user-management']);
      setSearchTerm(undefined);
      setFilteredMenu(
        menuItems.filter(function filterRecurssive(item) {
          return (
            (item.permissions ? hasPermission(item.permissions) : true) ||
            ((item.children || []).length ? item.children.some(filterRecurssive) : false)
          );
        })
      );
    }
  }

  return (
    <div className="flex flex-col h-full min-h-0 py-2 border-solid border-border border-y-0 border-x-0 border-r">
      <div className="pr-2">
        <Input.Search
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <div className="flex flex-col min-h-0 overflow-auto flex-1">
        <Menu
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          mode="inline"
          className="settings-menu"
          style={{ borderInlineEnd: 'none' }}
          onOpenChange={setOpenKeys}
          items={filteredMenu}
          onSelect={handleOnMenuSelected}
        />
      </div>
    </div>
  );
}
