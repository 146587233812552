import StartCase from 'lodash/startCase';

export const ALL_CAPABILITES = {
  Inventory: [
    {
      key: '1_1',
      label: 'View Inventory'
    },
    {
      key: '1_2',
      label: 'Create Inventory'
    },
    {
      key: '1_3',
      label: 'Delete Inventory'
    },
    {
      key: '1_4',
      label: 'Manage Inventory'
    },
    {
      key: '1_5',
      label: 'Update Inventory'
    }
  ],
  Dashboard: [
    {
      key: '2_1',
      label: 'View Dashboard'
    },
    {
      key: '2_2',
      label: 'Create Dashboard'
    },
    {
      key: '2_3',
      label: 'Delete Dashboard'
    },
    {
      key: '2_4',
      label: 'Manage Dashboard'
    },
    {
      key: '2_5',
      label: 'Update Dashboard'
    }
  ],
  Compliance: [
    {
      key: '3_1',
      label: 'View Compliance'
    },
    {
      key: '3_2',
      label: 'Create Compliance'
    },
    {
      key: '3_3',
      label: 'Delete Compliance'
    },
    {
      key: '3_4',
      label: 'Manage Compliance'
    },
    {
      key: '3_5',
      label: 'Update Compliance'
    }
  ],
  Vulnerability: [
    {
      key: '4_1',
      label: 'View Vulnerability'
    },
    {
      key: '4_4',
      label: 'View Manage Exceptions'
    }
  ],
  FIM: [
    {
      key: '13_1',
      label: 'View FIM'
    },
    {
      key: '13_2',
      label: 'Create FIM'
    },
    {
      key: '13_3',
      label: 'Delete FIM'
    },
    {
      key: '13_4',
      label: 'Manage FIM'
    },
    {
      key: '13_5',
      label: 'Update FIM'
    }
  ],
  Investigate: [
    {
      key: '5_1',
      label: 'View Investigate'
    },
    {
      key: '5_2',
      label: 'Create Investigate'
    },
    {
      key: '5_3',
      label: 'Delete Investigate'
    },
    {
      key: '5_4',
      label: 'Manage Investigate'
    },
    {
      key: '5_5',
      label: 'Update Investigate'
    }
  ],
  RootKit: [
    {
      key: '6_1',
      label: 'View RootKit'
    },
    {
      key: '6_2',
      label: 'Create RootKit'
    },
    {
      key: '6_3',
      label: 'Delete RootKit'
    },
    {
      key: '6_4',
      label: 'Manage RootKit'
    },
    {
      key: '6_5',
      label: 'Update RootKit'
    }
  ],
  Report: [
    {
      key: '7_1',
      label: 'View Report'
    },
    {
      key: '7_2',
      label: 'Create Report'
    },
    {
      key: '7_3',
      label: 'Delete Report'
    },
    {
      key: '7_4',
      label: 'Manage Report'
    },
    {
      key: '7_5',
      label: 'Update Report'
    }
  ],
  'Quick Checks': [
    {
      key: '8_1',
      label: 'View Quick Checks'
    },
    {
      key: '8_2',
      label: 'Create Quick Checks'
    },
    {
      key: '8_3',
      label: 'Delete Quick Checks'
    },
    {
      key: '8_4',
      label: 'Manage Quick Checks'
    },
    {
      key: '8_5',
      label: 'Update Quick Checks'
    }
  ],
  Settings: [
    {
      key: '9_1',
      label: 'View Settings'
    },
    {
      key: '9_2',
      label: 'Create Settings'
    },
    {
      key: '9_3',
      label: 'Delete Settings'
    },
    {
      key: '9_4',
      label: 'Manage Settings'
    },
    {
      key: '9_5',
      label: 'Update Settings'
    }
  ],
  Widget: [
    {
      key: '10_1',
      label: 'View Widget'
    },
    {
      key: '10_2',
      label: 'Create Widget'
    },
    {
      key: '10_3',
      label: 'Delete Widget'
    },
    {
      key: '10_4',
      label: 'Manage Widget'
    },
    {
      key: '10_5',
      label: 'Update Widget'
    }
  ],
  Alert: [
    {
      key: '11_1',
      label: 'View Alert'
    },
    {
      key: '11_2',
      label: 'Create Alert'
    },
    {
      key: '11_3',
      label: 'Delete Alert'
    },
    {
      key: '11_4',
      label: 'Manage Alert'
    },
    {
      key: '11_5',
      label: 'Update Alert'
    }
  ],
  Software: [
    {
      key: '14_1',
      label: 'View Software'
    },
    {
      key: '14_2',
      label: 'Create Software'
    },
    {
      key: '14_3',
      label: 'Delete Software'
    },
    {
      key: '14_4',
      label: 'Manage Software'
    }
  ],
  Configuration: [
    {
      key: '15_1',
      label: 'View Configuration'
    },
    {
      key: '15_2',
      label: 'Create Configuration'
    },
    {
      key: '15_3',
      label: 'Delete Configuration'
    },
    {
      key: '15_4',
      label: 'Manage Configuration'
    }
  ],
  Audit: [
    {
      key: '12_1',
      label: 'View Audit'
    }
  ],
  Patch: [
    {
      key: '16_1',
      label: 'View Patch'
    },
    {
      key: '16_2',
      label: 'Create Patch'
    },
    {
      key: '16_3',
      label: 'Delete Patch'
    },
    {
      key: '16_4',
      label: 'Manage Patch'
    }
  ]
};

const flattenPermissions = Object.keys(ALL_CAPABILITES).reduce(
  (prev, key) => ({
    ...prev,
    ...ALL_CAPABILITES[key].reduce(
      (p, i) => ({ ...p, [StartCase(i.label).replace(/\s/g, '_')]: i.key }),
      {}
    )
  }),
  {}
);

export default flattenPermissions;
